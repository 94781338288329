@import '../default';

.component {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-box {
  @include textShadow($colorBlack);
  position: absolute;
  background-color: rgba($colorMain2, 0.75);
  border-radius: 10px;
  box-shadow: 0 0 3px 1px $colorMain2;
  backdrop-filter: blur(3px);
  padding: 20px;
  color: $colorWhite;

  :any-link {
    color: $colorSecondary;
    text-decoration: none;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $colorBlue;
    }
  }

  &.what-we-do {
    max-width: 660px;
  }
}

.menu-wrapper {
  display: flex;
  margin: 10px;

  @include mobileMax {
    flex-direction: column;
  }
}

.link {
  padding: 3px 8px;
  background-color: $colorSecondary;
  border: 2px solid $colorSecondary;
  border-radius: 5px;
  margin: 0 10px;
  transition: color 0.25s ease-in-out,
  filter 0.25s ease-in-out,
  background-color 0.25s ease-in-out;

  &:any-link {
    color: $colorWhite;
  }

  &:hover {
    background-color: $colorTransparent;
    color: $colorSecondary !important;
    filter: drop-shadow(1px 1px 2px $colorMain2);
  }

  @include mobileMax {
    margin: 5px auto;
  }
}

.box-text {
  text-align: justify;
}

.contact-us-info {
  display: flex;

  @include mobileMax {
    flex-direction: column;
  }
}

.contact-us-column {
  margin: 20px;
  min-width: 200px;
}

.events-wrapper {
  display: flex;
  justify-content: space-between;

  @include mobileMax {
    flex-direction: column;
  }
}

.event-wrapper {
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-right: 5px;
  }

  &:last-of-type {
    margin-left: 5px;
  }

  @include mobileMax {
    &:first-of-type {
      margin-bottom: 5px;
    }

    &:last-of-type {
      margin-top: 5px;
    }
  }

  img {
    max-width: 300px;
    height:auto;

    @include mobileMax {
max-height: calc(100vh / 3);
      max-width: auto;
    }
  }
}