h1,
h2,
h3,
h4,
h5,
h6 {
  border-bottom: 1px solid $colorWhite;
  margin: 0;
  width: 100%;
  text-align: center;
  color: $colorWhite;
}

.site-wrapper {
  width: 100%;
  padding: 10px;
  background-color: $colorMain2;
  display: flex;
  box-sizing: border-box;
  align-items: stretch;
  margin: 10px auto !important;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba($colorBlue, 0.5);

  &.column {
    flex-direction: column-reverse;
  }

  @include largeDesktopMin() {
    width: 1220px;
    margin: 0 calc(50vw - 610px);
  }

  @include tabletMax {
    flex-direction: column;
  }
}

main {
  width: 100%;
  padding-right: 5px;
  box-sizing: border-box;

  @include tabletMax {
    padding: 0 0 5px;
  }

  @include desktopMin {
    min-width: 880px;
    max-width: 1200px;
  }
}

aside {
  min-width: 300px;
  max-width: 300px;
  padding-left: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tabletMax {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 5px 0 0;
  }
}

.aside-content {
  position: sticky;
  transition: top 0.25s ease-in-out;
  top: 10px;
}

.picture-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.25s linear;
    transform: scale(1.08);
  }

  &:hover img {
    transform: scale(1.1);
  }
}