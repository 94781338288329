@mixin textShadow($color) {
  text-shadow: $color 2px 0 0,
  $color 1.75517px 0.958851px 0,
  $color 1.0806px 1.68294px 0,
  $color 0.141474px 1.99499px 0,
  $color -0.832294px 1.81859px 0,
  $color -1.60229px 1.19694px 0,
  $color -1.97998px 0.28224px 0,
  $color -1.87291px -0.701566px 0,
  $color -1.30729px -1.5136px 0,
  $color -0.421592px -1.95506px 0,
  $color 0.567324px -1.91785px 0,
  $color 1.41734px -1.41108px 0,
  $color 1.92034px -0.558831px 0;
}

@mixin dropShadow($color) {
  filter: drop-shadow(0 0 1px $color) drop-shadow(0 0 1px $color) drop-shadow(0 0 1px $color);
}