$absoluteMinimum: 299px;
$mobileMin: 300px;
$mobileMax: 767px;
$tabletMin: 768px;
$tabletMax: 991px;
$desktopMin: 992px;
$desktopMax: 1199px;
$largeDesktopMin: 1200px;

@mixin absoluteMinimum {
  @media (max-width: #{$absoluteMinimum}) {
    @content;
  }
}

@mixin mobileMin {
  @media (min-width: #{$mobileMin}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobileMin}) and (max-width: #{$mobileMax}) {
    @content;
  }
}

@mixin mobileMax {
  @media (max-width: #{$mobileMax}) {
    @content;
  }
}

@mixin tabletMin {
  @media (min-width: #{$tabletMin}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tabletMin}) and (max-width: #{$tabletMax}) {
    @content;
  }
}

@mixin tabletMax {
  @media (max-width: #{$tabletMax}) {
    @content;
  }
}

@mixin desktopMin {
  @media (min-width: #{$desktopMin}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktopMin}) and (max-width: #{$desktopMax}) {
    @content;
  }
}

@mixin desktopMax {
  @media (max-width: #{$desktopMax}) {
    @content;
  }
}

@mixin largeDesktopMin {
  @media (min-width: #{$largeDesktopMin}) {
    @content;
  }
}

.max-mobile {
  @include tabletMin {
    display: none !important;
  }
}

.max-tablet {
  @include desktopMin {
    display: none !important;
  }
}

.min-tablet {
  @include mobileMax {
    display: none !important;
  }
}

.min-desktop {
  @include tabletMax {
    display: none !important;
  }
}