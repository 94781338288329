// Full colors
$colorBlack: #000;
$colorBlue: #4169e1;
$colorCyan: #00ffff;
$colorGray: #808080;
$colorGreen: #037d50;
$colorPink: #FF69B4;
$colorOrange: #FF4500;
$colorPurple: #663399;
$colorRed: #b82828;
$colorSilver: #999999;
$colorWhite: #EEE;
$colorYellow: #FFD700;
$colorTransparent: rgba(0, 0, 0, 0);

// BrightColors
$colorBrightBlue: #1E90FF;
$colorBrightWhite: #FFF;

// Light colors
$colorLightBlue: #AFEEEE;
$colorLightGreen: #9ACD32;
$colorLightOrange: #F4A460;
$colorLightYellow: #F0E68C;

// Dark colors
$colorDarkBlue: rgba(30, 34, 43, 1);
$colorDarkerBlue: #191c26;
$colorDarkWhite: rgba(221, 221, 221, 1);
$colorDarkGreen: #2F4F4F;

// Consts
$inisible: 0;
$hardlyVisible: 0.25;
$halfVisible: 0.5;
$mostlyVisible: 0.75;
$visible: 1;