html {
  font-size: 62.5%;
  width: 100vw;
  overflow: hidden auto;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1.6rem;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $colorMain;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  @include mobileMax {
    overflow: hidden;
  }
}

.link {
  &:any-link {
    color: $colorBlue;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }

  &:hover {
    color: $colorGreen;
  }
}